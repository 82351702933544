const options = [
  { value: "North America", label: "North America" },
  { value: "South America", label: "South America" },
  { value: "Central America", label: "Central America" },
  { value: "Caribbean", label: "Caribbean" },
  { value: "Central & South Asia", label: "Central & South Asia" },
  { value: "Northeastern Asia", label: "Northeastern Asia" },
  { value: "Caribbean", label: "Caribbean" },
  { value: "Southeastern Asia", label: "Southeastern Asia" },
  { value: "Australia and Oceania", label: "Australia and Oceania" },
  { value: "Northern Europe", label: "Northern Europe" },
  { value: "Southern Europe", label: "Southern Europe" },
  { value: "Eastern Europe", label: "Eastern Europe" },
  { value: "Western Europe", label: "Western Europe" },
  { value: "Middle East", label: "Middle East" },
  { value: "Northern Africa", label: "Northern Africa" },
  { value: "Southern Africa", label: "Southern Africa" },
];

module.exports = { options };
