import React from "react";

function ConnectUs() {
  return (
    <div className="h-[45px] w-[171px] bg-[#C8BAFD] text-[#3E21F3] lg:w-[160px] lg:h-[59px] rounded-[12px] py:3 px-4 md:px-6 md:py-4 md:w-[25%] flex gap-[14px] items-center justify-center transition-all ease-out duration-300 hover:bg-[#7E5BFF] hover:text-white font-fira-mono text-md font-semibold leading-6 sm:text-lg sm:leading-7 tracking-wide text-left sm:w-[30%]">
      SUBSCRIBE
    </div>
  );
}

export default ConnectUs;
