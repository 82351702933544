import ReactDOM from "react-dom/client";
import "./CSS/index.css";
import reportWebVitals from "./JS/reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./JS/App";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>
);

reportWebVitals();
